<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;">
    <div>
      <Breadcrumbs class="inlineblock" />
    </div>

    <!-- RIGHT CLICK MENU -->
    <RenameDeleteActions
      v-if="showMenu"
      v-model="showMenu"

      :x="x"
      :y="y"

      :renameAction="renameRole"
      :renameName="$t('general.rename')"
      :deleteAction="confirmDelete"
      :deleteName="$t('general.delete')"
    />
    <!-- RIGHT CLICK MENU -->

    <!-- NAMING AND RENAMING ROLES DIALOGS -->
    <NamingDialog
      v-model="isAddingRole"
      v-if="isAddingRole"

      :headlineName="$t('roles.new_role')"
      :typeName="$t('general.name')"

      :cancelName="$t('general.cancel')"                
      :cancelFunction="() => (isAddingRole = false)"
      :actionName="$t('general.add')"
      :actionFunction="addNewRole"
    />
    <NamingDialog
      v-if="isEditingRole"
      v-model="isEditingRole"

      :baseValue="editedRole.name"

      :headlineName="$t('roles.rename_role') + ' ' + editedRole.name"
      :typeName="$t('general.name')"

      :cancelName="$t('general.cancel')"                
      :cancelFunction="() => (isEditingRole = false)"
      :actionName="$t('general.rename')"
      :actionFunction="saveRole"
    />
    <!-- NAMING AND RENAMING ROLES DIALOGS -->

    <!-- EDIT ROLE TRANSLATION DIALOG -->
    <v-dialog
      v-if="isEditingRoleTranslation"
      v-model="isEditingRoleTranslation"
      persistent
      max-width="600"
    > 
      <v-card>
        <v-card-title>
          {{ $t("projecttypes.edit_translation") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="'Text'"
            outlined
            v-model="editedRoleTranslation.name"
            maxlength="255"
          ></v-text-field>
          <v-select
            :items="availableLanguages"
            label="Languages"
            v-model="editedRoleTranslation.language"
            disabled
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="isEditingRoleTranslation = false"
            class="no-transform"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            @click="saveRolesTranslation"
            class="no-transform"
          >
            {{ $t("general.rename") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDIT ROLE TRANSLATION DIALOG -->

    <!-- EDIT FUNCTION TRANSLATION DIALOG -->
    <v-dialog
      v-if="isEditingFunctionTranslation"
      v-model="isEditingFunctionTranslation"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          {{ $t("projecttypes.edit_translation") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="'Text'"
            outlined
            v-model="editedFunctionTranslation.name"
            maxlength="255"
          ></v-text-field>
          <v-select
            :items="availableLanguages"
            label="Languages"
            v-model="editedFunctionTranslation.language"
            disabled
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="isEditingFunctionTranslation = false"
            class="no-transform"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            @click="saveFunctionsTranslation"
            class="no-transform"
          >
            {{ $t("general.rename") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDIT FUNCTION TRANSLATION DIALOG -->

    <v-card
      :disabled="!canManageSecurity"
      elevation="0"
      :loading="reloadingRoles"
      class="pa-5"
    >
      <v-row>
        <v-col>
          <v-card tile class="pa-5">
           <v-row v-if="selectedRole && selectedRole != -1">
            <v-spacer/>
                <v-btn
                rounded
                small
                color="primary"
                dark
                style="text-transform:none"
                @click="renameRole"
              >
              {{ $t('general.rename') }}
            </v-btn>
              <v-btn
                style="text-transform:none"
                rounded
                small
                color="red"
                dark
                @click="confirmDelete"
              >
              {{ $t('general.delete') }}
            </v-btn>
            </v-row>
            <v-list dense>
              <v-subheader>{{ $t('roles.roles') }}</v-subheader>
                <v-list-item
                  v-for="(item, i) in allRoles"
                  :key="i"
                  @click="roleChanged(i)"
                  @contextmenu="show($event, item)"
                  :class="{'active-role' : i == selectedRoleIdx}"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-account-group</v-icon>
                    <v-badge
                      style="top: 12px; left: -3px"
                      inline
                      color="green"
                      :content="item.users.length.toString()"
                    >
                    </v-badge>
                  </v-list-item-icon>
                </v-list-item>
            </v-list>
            <v-btn
              @click="openNewRole"
              class="ma-2 add-role-btn"
              rounded
              color="primary"
              dark
              >{{ $t('roles.add_role') }}</v-btn
            >
          </v-card>

          <v-card class="pa-5 mt-10">
            <v-subheader style="font-size: 0.75rem">{{
              $t("roles.role_translations")
            }}</v-subheader>
            <v-data-table
              :headers="headers"
              :items="selectedRoleTranslations"
              @click:row="editRoleTranslation"
              item-key="index"
              disable-pagination
              hide-default-footer
            >
            </v-data-table>
          </v-card>

          <v-card v-if="selectedRole" tile class="pa-5 mt-10">
            <v-list dense>
                <v-subheader>{{ $t('roles.users') }}</v-subheader>
                <div style="max-height:400px;overflow:auto;">
                  <v-list-item v-for="(item, i) in selectedRole.users" :key="i" @click="$router.push(`/user/${item.id}`)">
                    <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
                    <v-list-item-title v-text="item.firstname + ' ' + item.lastname"></v-list-item-title>
                </v-list-item>
                </div>
            </v-list>
          </v-card>
        </v-col>
        <v-col>
          <v-card tile class="pa-5">
            <v-list dense :disabled="selectedRoleIdx == -1">
              <v-subheader>{{ $t('roles.functions') }}</v-subheader>
              <div style="max-height:600px;overflow:auto;">
                <v-list-item
                  v-for="(item, i) in allFunctions"
                  :key="i"
                  :class="{'active-role' : i == selectedFunctionIdx}"
                  @click="functionNameChanged(i)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="item.selected ? '#009f4d' : null" @click="functionChanged(item)">{{
                      item.selected
                        ? "mdi-radiobox-marked"
                        : "mdi-radiobox-blank"
                    }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </div>
            </v-list>
          </v-card>

          <v-card class="pa-5 mt-10">
            <v-subheader style="font-size: 0.75rem">{{
              $t("roles.function_translations")
            }}</v-subheader>
            <v-data-table
              :headers="headers"
              :items="selectedFunctionTranslations"
              @click:row="editFunctionTranslation"
              item-key="index"
              disable-pagination
              hide-default-footer
            >
            </v-data-table>
          </v-card>

        </v-col>
      </v-row>
    </v-card>

    <ConfirmDialog
      v-if="confirmDialog"
      v-model="confirmDialog"

      :title="$t('confirm.head')"
      :description="$t('confirm.delete_role')"

      :cancelName="$t('reportInfo.no')"
      :actionName="$t('reportInfo.yes')"
      :actionFunction="deleteRole"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";

import Breadcrumbs from "@/components/Breadcrumbs";
import RenameDeleteActions from "@/components/actions/RenameDeleteActions.vue";
import NamingDialog from '@/components/dialogs/NamingDialog.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import { sortByProperties, sortByProperty, updateFunctionTranslation, getLanguage } from '@/utils/utils';
import UserService from "@/services/user.service";

export default {
  components: {
    Breadcrumbs,
    RenameDeleteActions,
    NamingDialog,
    ConfirmDialog
  },
  data() {
    return {
      reloadingRoles: false,
      allRoles: [],
      allFunctions: [],
      selectedRoleIdx: -1,
      selectedFunctionIdx: -1,
      selectedFunctionId: null,
      selectedFunction: null,
      selectedRoleId: null,
      isAddingRole: false,
      newRole: {
        name: "",
      },
      x: 0,
      y: 0,
      showMenu: false,
      selectedRole: null,
      isEditingRole: false,
      editedRole: null,
      confirmDialog: false,
      isEditingRoleTranslation: false,
      isEditingFunctionTranslation: false,
      headers: [
        {
          text: "Name",
          value: "name",
          width: "50%",
        },
        { text: "Language", value: "language" },
      ],
    };
  },
  computed: {
    ...mapGetters(["canManageSecurity"]),
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      availableLanguages: (state) => state.availableLanguages,
    }),
    selectedRoleTranslations() {
      var translations = [];
      try {
        var foundTranslations = null;
        let role = this.allRoles.find(
          (pt) => pt.id == this.selectedRoleId
        );
        if (role) {
          foundTranslations = role.roles_translations;
        }

        if (foundTranslations) {
          for (var i = 0; i < this.availableLanguages.length; i++) {
            var translation = foundTranslations.find(
              (t) => t.language == this.availableLanguages[i]
            );
            var id = -1;
            var name = "";

            if (translation) {
              id = translation.id;
              name = translation.name;
            }

            translations.push({
              id: id,
              name: name,
              language: this.availableLanguages[i],
            });
          }
        }
      } catch (err) {
        console.log(err)
      }

      return translations;
    },
    selectedFunctionTranslations() {
      var translations = [];
        try {
          var foundTranslations = null;
          let selectedFunction = this.allFunctions.find(
            (f) => f.id == this.selectedFunctionId
          );
          if (selectedFunction) {
            foundTranslations = selectedFunction.functions_translations;
          }

          if (foundTranslations) {
            for (var i = 0; i < this.availableLanguages.length; i++) {
              var translation = foundTranslations.find(
                (t) => t.language == this.availableLanguages[i]
              );
              var id = -1;
              var name = "";

              if (translation) {
                id = translation.id;
                name = translation.name;
              }

              translations.push({
                id: id,
                name: name,
                language: this.availableLanguages[i],
              });
            }
          }
        } catch (err) {
          console.log(err)
        }
        
        return translations;
    },
  },
  async mounted() {
    this.reloadingRoles = true;
    this.setBreadcrumbs([{ text: this.$t("general.settings"), href: "/settings" }, { text: this.$t("user.security"), href: "/security" }]);

    try {
      await this.getAllRoles();
    }
    catch(err) {
      this.$router.push("/");
    }

    this.reloadingRoles = false;
    this.roleChanged(0);
    this.functionNameChanged(0);
  },
  methods: {
    ...mapMutations({
      openToast: "openToast",
      SET_AVAILABLE_LANGUAGES: 'SET_AVAILABLE_LANGUAGES'
    }),
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
    }),
    openNewRole() {
      this.isAddingRole = true; 
    },
    roleChanged(idx) {
      this.selectedRoleIdx = idx; // Set selected index
      this.setSelected();
    },
    functionNameChanged(idx) {
      this.selectedFunctionIdx = idx;
      this.setSelectedFunction();
    },
    setSelectedFunction() {
      this.selectedFunction = this.allFunctions[this.selectedFunctionIdx];
      this.selectedFunctionId = this.selectedFunction.id;
    },
    setSelected() {
      var scope = this;

      this.selectedRole = this.allRoles[this.selectedRoleIdx];
      console.log(this.selectedRole)
      if (this.selectedRole) {
        this.selectedRoleId = this.selectedRole.id;

        // Unselect all functions.
        this.allFunctions.forEach((f) => {
          f.selected = false;
        });

        this.selectedRole.functions.forEach((roleFunction) => {
          // Select role functions.
          var idxFunction = scope.allFunctions.findIndex(
            (f) => f.id === roleFunction.id
          );
          scope.allFunctions[idxFunction].selected = true;
        });

        sortByProperties(this.selectedRole.users, ['firstname', 'lastname']);
      }

      this.$forceUpdate();
    },
    editRoleTranslation(translation) {
      this.isEditingRoleTranslation = true;
      this.editedRoleTranslation = JSON.parse(JSON.stringify(translation));
      console.log(translation);
    },
    editFunctionTranslation(translation) {
      this.isEditingFunctionTranslation = true;
      this.editedFunctionTranslation = JSON.parse(JSON.stringify(translation));
      console.log(translation)
    },
    functionChanged(func) {
      var foundFunction = this.allFunctions.find((f) => f.id == func.id);

      if (foundFunction) {
        foundFunction.selected = !foundFunction.selected;

        let changes = {
          changes: {
            roleId: this.allRoles[this.selectedRoleIdx].id,
            functionChanged: foundFunction,
          },
        };

        this.saveChanges(changes);
      }
    },
    saveRolesTranslation() {
      let changes = {
        changes: {
          roleId: this.selectedRoleId,
          roleTranslationId: this.editedRoleTranslation.id,
          name: this.editedRoleTranslation.name,
          language: this.editedRoleTranslation.language,
        },
      };
      console.log(changes)

      this.saveRoleTranslation(changes, this.$i18n.locale).then(() => {
        this.isEditingRoleTranslation = false;
        this.setSelected();
      });
      
    },
    saveFunctionsTranslation() {
      let changes = {
        changes: {
          functionId: this.selectedFunctionId,
          functionTranslationId: this.editedFunctionTranslation.id,
          name: this.editedFunctionTranslation.name,
          language: this.editedFunctionTranslation.language,
        }
      };
      console.log(changes)

      this.saveRoleTranslation(changes, this.$i18n.locale).then(() => {
        this.isEditingFunctionTranslation = false;
        this.setSelected();
      });

    },
    saveChanges(data) {
      var scope = this;

      this.saveRoles({ data: data, language: this.$i18n.locale }).then(() => {
       scope.setSelected();
      });

    },
    async addNewRole(newRoleName) {
      this.isAddingRole = false;

      await this.addRole({ name: newRoleName, language: this.$i18n.locale });

      this.selectedRoleIdx = this.allRoles.findIndex(r => r.name == newRoleName);
      this.setSelected();
    },
    show(e, item) {
      e.preventDefault();
      this.selectedRole = item;
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    deleteRole() {
      this.removeRole({ role: this.selectedRole, language: this.$i18n.locale});
      this.newRole.name = "";
      this.confirmDialog = false;

      this.selectedRoleIdx = null;
      this.setSelected();
    },
    renameRole() {
      this.isEditingRole = true;
      this.editedRole = JSON.parse(JSON.stringify(this.selectedRole));
    },
    saveRole(newRoleName) {
      let changes = {
        changes: {
          roleId: this.editedRole.id,
          name: newRoleName,
        },
      };

      this.saveChanges(changes);
      this.isEditingRole = false;
    },
    confirmDelete() {
      this.confirmDialog = true;
    },
    async getAllRoles() {
      let response = await UserService.getRoles();
      this.SET_ALL_ROLES_AND_FUNCTIONS(response.data);
    },
    async saveRoles(data) {
      let response = await UserService.saveRoles(data.data, data.language);
      this.SET_ALL_ROLES(response.data.roles);
      this.SET_ALL_FUNCTIONS(response.data.functions);
    },
    async saveRoleTranslation(data, language) {
      let response = await UserService.saveRoleTranslation(data, language);
      this.SET_ALL_ROLES_AND_FUNCTIONS(response.data);
    },
    async saveFunctionTranslation(data, language) {
      let response = await UserService.saveRoleTranslation(data, language);
      this.SET_ALL_ROLES_AND_FUNCTIONS(response.data);
    },
    async addRole(data) {
      let response = await UserService.addRole({ roleName: data.name }, data.language);
      if (response.data.roles){
        this.SET_ALL_ROLES(response.data.roles);
        this.SET_ALL_FUNCTIONS(response.data.functions);
      }
    },
    async removeRole(data)  {
      try {
        let response = await UserService.removeRole(data.role, data.language);
        if (response.data.roles) {
          this.SET_ALL_ROLES(response.data.roles);
          this.SET_ALL_FUNCTIONS(response.data.functions);
        }
        
        this.openToast({ message: this.$t("roles.deletion_success"), duration: 5000, color: 'green'});
      }
      catch (err) {
        this.openToast({ message: this.$t("roles.role_in_use"), duration: 5000, color: 'red'});
      }
    },
    SET_ALL_ROLES_AND_FUNCTIONS(data) {

      let roles = data.roles;
      let functions = data.functions;
      let languages = data.languages;
      // F.R. 2021-07-22 J'ai retiré la traduction des rôles, on veut pouvoir changer le nom général lors de la configuration.
      console.log('SET_ALL_ROLES_AND_FUNCTIONS', roles, functions, languages)

      roles.forEach(r => {
        let linkedUsers = null;
        r.users.forEach(u => {
          if (linkedUsers != null) {
            linkedUsers += ', ' + u.firstname + ' ' + u.lastname;
          }
          else {
            linkedUsers = u.firstname + ' ' + u.lastname;
          }
        });
        r.linkedUsers = linkedUsers;
      })

      if (languages) {
        this.SET_AVAILABLE_LANGUAGES(languages);
      }
      this.allRoles = roles;

      updateFunctionTranslation(functions, getLanguage());

      sortByProperty(functions, 'name');
    
      this.allFunctions = functions;
    },

    SET_ALL_ROLES(value) {
      this.allRoles = value;
    },
    SET_ALL_FUNCTIONS(functions) {

      sortByProperty(functions, 'name');
      console.log('SET_ALL_FUNCTIONS', functions)

      updateFunctionTranslation(functions, getLanguage());

      this.allFunctions = functions;
    },
  }
};
</script>

<style>
.add-role-btn {
  left: calc(50% - 101px);
  width: 200px;
  text-transform: none;
}
.no-transform {
  text-transform: none;
}
.active-role{
  border-radius:8px;
  background: rgba(0, 159, 77, 0.1);
  color:#009f4d;
}
</style>
